import { AccountFormModel } from '../components/views/account/account-form.model';

export class TechnicianUpdateRequestPayload {
  public readonly profile: any = {};
  public readonly technician: any = {};

  constructor(formValue: AccountFormModel) {
    this.profile = {};
    this.profile.firstName = formValue.firstName;
    this.profile.lastName = formValue.lastName;
    this.profile.isStaffie = formValue['isStaffie'];
    this.technician = {};
    this.technician.mroUuid = formValue['mroUuid'];
    this.technician.presentation = formValue.presentation;
    this.technician.experience = formValue.experience;
    this.technician.ameTitles = formValue.ameTitles;
    this.technician.otherAirplanes = formValue.otherAirplanes;
    this.technician.airplanes = formValue.airplanes;
    this.technician.languages = formValue.languages;
    this.technician.country = formValue.country;
    this.technician.nationalities = formValue.nationalities;
    this.technician.street = formValue.street;
    this.technician.state = formValue.state;
    this.technician.city = formValue.city;
    this.technician.zipCode = formValue.zipCode;
    this.technician.apartment = formValue.apartment;
    this.technician.userPhone = formValue.userPhone;
    this.technician.isTcn = formValue.isTcn;
    this.technician.licenses = formValue.licenses;
    this.technician.licenseNumber = formValue.licenseNumber;
    this.technician.preferredWorkShifts = formValue.preferredWorkShifts;
    this.technician.preferredContractType = formValue.preferredContractType;
    this.technician.preferredPayRangeMin = formValue.preferredPayRangeMin;
    this.technician.preferredPayRangeMax = formValue.preferredPayRangeMax;
  }
}
