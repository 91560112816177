import {Component, ElementRef, EventEmitter, HostBinding, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';

import {ToastMessageService} from '@libs/toast-messages/toast-message.service';

import {FileUploadService} from '../services/file-upload.service';
import {ModalWindowComponent} from '../modal-window/modal-window.component';
import {ModalService} from '../services/modal.service';
import {DocumentType, StaffnowDocument} from '@libs/user-profile/model/documents.model';
import {tooltipContent} from '@libs/shared/constants/tooltip.constants';
import {fileUploadConstants} from '@libs/shared/constants/fileupload.constants';
import {getUrl} from '@libs/shared/bms-common/rest/resource.utils';
import {UserProfileLinkRel} from '@libs/shared/linkrels/user-profile.linkrel';
import {isPlatformWeb} from "@libs/shared/helpers/capacitor";
import {ErrorMessageService} from "@libs/common-ui/services/error-message/error-message.service";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'staffnow-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit, OnChanges {
  @Input() public noDocumentsUploadedDisclaimer: string;
  @Input() public filesAddedForWarning: number;
  @Input() public filesRemovedForWarning: number;
  @Input() public canEdit: boolean;
  @Input() public isAdmin?: boolean;
  @Input() public documentType: DocumentType;
  @Input() public documents: StaffnowDocument[];
  @Input() public userProfile: any;
  @Input() @HostBinding('class.rejected-data') public isRejected = false;
  @Input() @HostBinding('class.missing-data') public isMissing = false;
  @Input()
  @HostBinding('class.display-diploma-banner')
  public shouldDisplayDiplomaBanner = false;
  @HostBinding('class.display-help-banner')
  public shouldDisplayQuickDropBanner = false;
  @HostBinding('class.display-id-document-banner')
  public shouldDisplayIdDocumentBanner = false;

  @Output() public singleDocumentStatus: EventEmitter<any> = new EventEmitter();
  @Output() public successfulFileUpload: EventEmitter<{ context: string }> = new EventEmitter();
  @Output() public deleteFile: EventEmitter<any> = new EventEmitter();
  @Output() public downloadFile: EventEmitter<any> = new EventEmitter();
  @Output() public onSetExpirationDate: EventEmitter<any> = new EventEmitter();
  @Output() public onSetPossession: EventEmitter<DocumentType> = new EventEmitter();

  @ViewChild('modalWindow') private modalWindow: ModalWindowComponent;
  @ViewChild('fileInput') private fileInput: ElementRef;

  public apiUrl: string;
  public userUuid: string;
  public documentDescription = ''; //TODO(SN-973): Consider how the description will be added.
  public inputAccepts: string;
  public uploader: any;
  public fileRestrictionsTooltip: string;
  public fileUploadConstants: any;
  public readonly tooltipContent = tooltipContent;
  public hasBaseDropZoneOver = false;
  public file: any;

  protected readonly isPlatformWeb: boolean = isPlatformWeb();

  constructor(
    public uploadService: FileUploadService,
    private confirmService: ModalService,
    private toastMessageService: ToastMessageService,
    private errorMessageService: ErrorMessageService,
    private translateService: TranslateService
  ) {
  }

  get shortListLimit(): number {
    return this.shouldDisplayDiplomaBanner || this.shouldDisplayQuickDropBanner
      ? 1
      : fileUploadConstants.shortListLimit;
  }

  public ngOnChanges(simpleChange: SimpleChanges) {
    this.getApiUrlAndTechnicianUuid();
    this.checkIfDocumentsChange(simpleChange);
  }

  private getApiUrlAndTechnicianUuid() {
    this.apiUrl = getUrl(
      this.userProfile,
      UserProfileLinkRel.DownloadAllDocuments
    ).split('?')[0];
    this.userUuid = this.userProfile._embedded.profile.userUuid;
  }

  public ngOnInit() {
    this.getApiUrlAndTechnicianUuid();
    this.shouldDisplayDiplomaBanner =
      this.canEdit && this.documentType.context === 'DIPLOMA';
    this.shouldDisplayQuickDropBanner =
      this.canEdit && this.documentType.context === 'QUICK_DROP';
    this.shouldDisplayIdDocumentBanner =
      this.canEdit && this.documentType.context === 'ID_DOCUMENT';
    this.fileUploadConstants = fileUploadConstants;
    this.inputAccepts = this.uploadService.fileListToExtensionCSV(
      this.documentType.allowedFileTypes
    );
    this.fileRestrictionsTooltip = isPlatformWeb() ? this.translateService.instant('FILES.FILE_RESTRICTION_TOOLTIP', {
      inputAccepts: this.inputAccepts,
      fileSizeLimit: this.documentType.fileSizeLimit,
      limit2: fileUploadConstants.limit,
      maximumFilesLimit: this.documentType.maximumFilesLimit
    }) : null;
    this.uploadService.configUploader(
      this.apiUrl,
      this.documentType.fileSizeLimit,
      this.documentType.allowedFileTypes
    ).then(uploader => {
      this.uploader = uploader;
      this.uploader.onBuildItemForm = (fileItem: any, form: any) => {
        const documentInfo = {
          context: this.documentType.context,
          description: this.documentDescription,
          mime: fileItem.file.type,
          technicianUuid: this.userUuid
        };

        if (!isPlatformWeb()) {
          fileItem.file.name = new Date().toISOString() + fileItem.file.name;
        }

        form.append(
          'document',
          new Blob([JSON.stringify(documentInfo)], {type: 'application/json'})
        );
      };

      this.uploader.onWhenAddingFileFailed = (fileItem: any, filter: any) => {
        this.uploader.queue.find((file, i) => {
          if (file === fileItem) {
            this.uploader.queue.splice(i, 1);
          }
        });

        switch (filter.name) {
          case 'fileSize':
            const fileSizeLimitBytes = this.documentType.fileSizeLimit * 1000000;
            const mime: string = fileItem.rawFile.type;
            if (mime.includes("image")) {
              const downsizeRate = fileSizeLimitBytes / fileItem.rawFile.size;
              this.uploadService.downsizeImage(
                fileItem,
                this.apiUrl,
                downsizeRate,
                {
                  context: this.documentType.context,
                  description: this.documentDescription,
                  mime: fileItem.rawFile.type,
                  technicianUuid: this.userUuid
                },
                (response) => this.handleResizeResponse(response)
              );
            } else {
              this.toastMessageService.fail(
                this.translateService.instant(fileUploadConstants.fileExceededLimit)
              );
            }
            break;
          case 'mimeType':
            this.toastMessageService.fail(
              this.translateService.instant(fileUploadConstants.fileHasUnsupportedFormat)
            );
            break;
          case 'queueLimit':
            this.toastMessageService.fail(
              this.translateService.instant(
                fileUploadConstants.uploaderQueueLimitExceeded,
                {limit: this.uploader.options.queueLimit})
            );
            break;
          default:
            break;
        }

        if (this.uploader.queue.length === 0) {
          this.fileInput.nativeElement.value = '';
        }
      };

      this.uploader.onSuccessItem = (
        fileItem: any,
        response: string,
        status: number
      ) => {
        this.uploader.queue.find((file, i) => {
          if (file === fileItem) {
            this.uploader.queue.splice(i, 1);
          }
        });

        if (this.uploader.queue.length === 0) {
          this.successfulFileUpload.emit({context: this.documentType.context});
          this.toastMessageService.success(
            this.translateService.instant(fileUploadConstants.successfullFileUpload)
          );
          this.fileInput.nativeElement.value = '';
        }
      };

      this.uploader.onErrorItem = (
        fileItem: any,
        response: string,
        status: number
      ) => {
        this.uploader.queue.find((file, i) => {
          if (file === fileItem) {
            this.uploader.queue.splice(i, 1);
          }
        });

        if (!!response && response.length > 0) {
          this.errorMessageService.handleErrorResponse(JSON.parse(response));
        }

        if (this.uploader.queue.length === 0) {
          this.fileInput.nativeElement.value = '';
        }
      };
    });
  }

  public handleDocumentStatusChange(documentReviewStatus: {
    uuid: string;
    status: string;
    fileUrl: string;
  }) {
    this.singleDocumentStatus.emit(documentReviewStatus);
  }

  public removeFile(item: any): void {
    this.confirmService.openConfirmModal('SYSTEM.INFO.DELETE_DOC', () =>
      this.deleteFile.emit(item)
    );
  }

  public openModalWithAllFiles(): void {
    this.modalWindow.open();
  }

  public downloadDocument(item) {
    this.downloadFile.emit(item.fileUrl);
  }

  public fileOverBase(event: boolean, source: string = 'help'): void {
    if (event || (!event && source == 'help')) {
      this.hasBaseDropZoneOver = event;
    }
  }

  get missingDataTooltip(): string {
    return this.isMissing ? this.translateService.instant('FILES.MISSING_DATA') : '';
  }

  /*
      This is needed so the documents section won't delete the files
      from the upload section once it's uploaded and then add it again
      when the request for the profile is completed
  */
  private checkIfDocumentsChange(simpleChange: SimpleChanges) {
    const documentsChange = simpleChange['documents'];
    if (
      documentsChange &&
      (!documentsChange.previousValue ||
        documentsChange.currentValue.length !==
        documentsChange.previousValue.length)
    ) {
      if (this.uploader) {
        this.uploader.queue = [];
      }
    }
  }

  private handleResizeResponse(response) {
    response.subscribe(() => {
      this.successfulFileUpload.emit({context: this.documentType.context});
      this.toastMessageService.success(
        this.translateService.instant(fileUploadConstants.successfullFileUpload)
      );
    }, response => {
      
      this.errorMessageService.handleErrorResponse(response, 'UPLOAD.IMAGE_FAILED');
    });
  }

  public handleSetExpirationDate(event): void {
    this.onSetExpirationDate.emit(event);
  }

  public handleSetPossession(documentType: DocumentType): void {
    this.onSetPossession.emit(documentType);
  }

  get hasAnyDocumentChanged(): boolean {
    return this.filesAddedForWarning > 0 || this.filesRemovedForWarning > 0;
  }

  onTooltipClick(tooltipMessage) {
    if (!isPlatformWeb()) {
      this.toastMessageService.info(tooltipMessage);
    }
  }
}
