<!-- This is the main upload form template represented as a card component -->
<ng-container *ngTemplateOutlet="uploadForm"></ng-container>

<!-- This is the main upload form passed to modal window -->
<staffnow-modal-window [content]="uploadForm" #modalWindow></staffnow-modal-window>

<!-- This is the main upload form template -->
<ng-template #uploadForm let-isInModal="isInModal">
    <div
        class="file-uploader-container"
        [ngClass]="{ 'uploader-card': !isInModal, isAdmin: isAdmin }"
        [tooltip]="isPlatformWeb ? (missingDataTooltip) : ''"
        placement="bottom"
    >
        <span *ngIf="isMissing" class="alert-icon" (click)="onTooltipClick(missingDataTooltip)"> warning </span>
        <div class="file-uploader-head">
            <h1 class="h1">
                <strong>
                    {{ documentType.title + (documentType.isMandatory ? '*' : '') }}
                </strong>
            </h1>
            <form>
                <input
                    ng2FileSelect
                    #fileInput
                    hidden
                    type="file"
                    name="file"
                    [accept]="inputAccepts"
                    [multiple]="documentType.multipleFileSelect"
                    [uploader]="uploader"
                />
                <general-button
                    *ngIf="canEdit"
                    (onClick)="fileInput.click()"
                    [tooltip]="fileRestrictionsTooltip"
                    placement="bottom"
                    size="small"
                    [text]="'GENERAL.BROWSE' | translate | uppercase"
                ></general-button>
            </form>
        </div>
        <staffnow-information-banner
            *ngIf="shouldDisplayDiplomaBanner"
            size="small"
            color="blue"
            direction="horizontal"
            [message]="'FILES.LICENSE_MESSAGE' | translate"
        ></staffnow-information-banner>
        <staffnow-information-banner
            *ngIf="shouldDisplayQuickDropBanner"
            size="small"
            color="blue"
            direction="horizontal"
            [message]="'FILES.DOC_IN_RESPECTIVE_PLACE' | translate"
        ></staffnow-information-banner>
        <staffnow-information-banner
            *ngIf="shouldDisplayIdDocumentBanner"
            size="small"
            color="blue"
            direction="horizontal"
            [message]="'FILES.DOC_VISIBLE_TO_SERVICE_CENTER' | translate"
        ></staffnow-information-banner>
        <div
            *ngIf="canEdit"
            ng2FileDrop
            class="file-uploader-body"
            [ngClass]="{
                dragover: hasBaseDropZoneOver,
                'with-scroll': isInModal
            }"
            (fileOver)="fileOverBase($event, 'wrapper')"
        >
            <div ng2FileDrop class="dragover-help" [uploader]="uploader" (fileOver)="fileOverBase($event)">
                <span>{{'FILES.DROP_HERE' | translate }}</span>
            </div>
            <staffnow-file-list
                [documents]="documents"
                [isAdmin]="isAdmin"
                [canEdit]="canEdit"
                [uploader]="uploader"
                (singleDocumentStatus)="handleDocumentStatusChange($event)"
                (fileDeleted)="removeFile($event)"
                (downloaded)="downloadDocument($event)"
                (expirationDateSet)="handleSetExpirationDate($event)"
                [maximumFilesLimit]="documentType.maximumFilesLimit"
                [isFileOver]="hasBaseDropZoneOver"
                [noDocumentsUploadedDisclaimer]="noDocumentsUploadedDisclaimer"
            >
            </staffnow-file-list>
        </div>
        <div
            *ngIf="!canEdit"
            class="file-uploader-body"
            [ngClass]="{
                'with-scroll': isInModal
            }"
        >
            <staffnow-file-list [isReadOnly]="true" [documents]="documents" (downloaded)="downloadDocument($event)">
            </staffnow-file-list>
        </div>
        <div
            *ngIf="documents.length > shortListLimit && !isInModal"
            class="file-uploader-footer"
            [ngClass]="{ longList: documents.length > shortListLimit }"
            (click)="documents.length > shortListLimit && openModalWithAllFiles()"
        >
            <strong>
                <span>{{ documents.length }} {{ 'files - see all' | translate }}</span>
            </strong>
        </div>
        <div class="custom-control custom-checkbox" *ngIf="documentType.hasPossessionCheck">
            <input
                type="checkbox"
                class="custom-control-input"
                id="{{ 'has-possession-' + documentType.context }}"
                [checked]="documentType.hasPossession"
                (click)="handleSetPossession(documentType)"
            />
            <label for="{{ 'has-possession-' + documentType.context }}" class="custom-control-label font-weight-bold">
                I'm in possession of the documents.
            </label>
        </div>
        <staffnow-info-icon-with-tooltip
            [displayHelpLabel]="true"
            [big]="true"
            [placement]="'right'"
            [tooltipMessage]="documentType.tooltip | translate"
        ></staffnow-info-icon-with-tooltip>
        <div *ngIf="hasAnyDocumentChanged" class="warning-to-changes">
            {{'FILES.CHANGED_FILES' | translate: {added: filesAddedForWarning, removed: filesRemovedForWarning} }}
        </div>
    </div>
</ng-template>
